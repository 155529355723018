import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export const registerCustomer = (payload) => {
  return axios.post(`${EMI_BOOK}/v1/mdm/register-customer`, payload, {
    headers: getHeaders(true),
  });
};
export function customerRegistrations(payload) {
  return axios.get(`${EMI_BOOK}/v2/mdm/customer-registrations`, {
    params: {
      page: payload.page,
      imei1: payload.imei1,
      imei2: payload.imei2,
      mobile: payload.mobile,
      size: payload.size,
    },
    headers: getHeaders(true),
  });
}

export const registerCustomerV2 = (payload) => {
  return axios.post(`${EMI_BOOK}/v1/mdm/register-customer-v2`, payload, {
    headers: getHeaders(true),
  });
};

export const syncData = (id) => {
  return axios.post(`${EMI_BOOK}/v1/mdm/sync-data/${id}`, null, {
    headers: getHeaders(true),
  });
};

export const updateEMIDetails = (id, payload) => {
  return axios.put(`${EMI_BOOK}/v1/mdm/emi-details/${id}`, payload, {
    headers: getHeaders(true),
  });
};

export const updateEMIStatus = (id, emiId, status) => {
  return axios.put(`${EMI_BOOK}/v1/mdm/emi-status/${id}/${emiId}`, null, {
    params: { status },
    headers: getHeaders(true),
  });
};

export const updateKYCDetails = (id, payload) => {
  return axios.put(`${EMI_BOOK}/v1/mdm/kyc-details/${id}`, payload, {
    headers: getHeaders(true),
  });
};

export const requestLockUnlock = (id, status) => {
  return axios.post(`${EMI_BOOK}/v1/mdm/request-lock-unlock/${id}`, null, {
    params: { status },
    headers: getHeaders(true),
  });
};

export const requestUnistall = (id, status) => {
  return axios.post(`${EMI_BOOK}/v2/mdm/request-uninstall/${id}`, null, {
    headers: getHeaders(true),
  });
};

export const getDevices = (pageToken) => {
  return axios.get(`${EMI_BOOK}/v2/mdm/devices`, {
    headers: getHeaders(true),
    params: { pageToken },
  });
};
