import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

import { Button } from "@mui/material";
import { getDevices } from "apis/customerRegistration";

export default function Devices() {
  const color = "light";

  const [devices, setDevices] = useState([]);

  const [pageToken, setPageToken] = useState(null);
  const [navigate, setNavigate] = useState({ next: null, prev: null });
  const [page, setPage] = useState(0);

  const handleGetDevices = async () => {
    try {
      const { data } = await getDevices(pageToken);

      setDevices(data.devices);
      setNavigate((prev) => ({
        next: data.nextPageToken, // Set the next page token from the API response
      }));
      setPage((prev) => (!pageToken ? 0 : prev + 1));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    handleGetDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageToken]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }>
          <div className="flex justify-evenly my-4">
            <Button
              type="submit"
              disabled={!navigate.next}
              variant="contained"
              onClick={() => {
                if (navigate.next) {
                  setPageToken(navigate.next);
                }
              }}>
              Next
            </Button>
          </div>
        </div>{" "}
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S No." />
                <TableHeader heading="Name" />
                <TableHeader heading="Serial Number" />
                <TableHeader heading="imei" />
                <TableHeader heading="model" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {devices.map((device, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={page * 100 + i + 1} />
                    <TableElement
                      value={
                        <span className="font-semibold">{device?.name}</span>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {device?.hardwareInfo?.serialNumber}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {device?.networkInfo?.imei}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <div className="flex flex-col">
                          <span className="font-semibold">
                            {device?.hardwareInfo?.brand}
                          </span>
                          <span className="font-semibold">
                            {device?.hardwareInfo?.model}
                          </span>
                        </div>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
